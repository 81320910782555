import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import CompetenceTypeBadge from "../../../../components/competences/CompetenceTypeBadge";
import {
  BaseCompetence,
  CompetenceType
} from "../../../../models/Form/BaseCompetence";
import { SupportedLanguages } from "../../../../models/LangField";
import PersonalGradingBadge from "../../../../components/employees/PersonalGradingBadge";
import { PersonalGrading } from "../../../../models/Employee/Employee";
import SafeHTMLText from "../../../../components/texts/SafeHTMLText";

type OwnProps = {
  competence: BaseCompetence;
  lang: SupportedLanguages;
};

const CompetenceMeta: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "competences"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const { competence, lang = "fr" } = props;

  return (
    <section aria-labelledby="applicant-information-title">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="applicant-information-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            {t("meta.title")}
          </h2>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t("fields.type")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">
                <CompetenceTypeBadge type={competence.relatedType} />
              </dd>
            </div>
            {competence.relatedType !== CompetenceType.SPECIFIC && (
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">
                  {t("fields.competence")}
                </dt>
                <dd className="mt-1 text-xl font-semibold text-indigo-600">
                  {competence.relatedType === CompetenceType.GENERIC &&
                    competence?.genericCompetence?.name?.[lang]}
                  {competence.relatedType === CompetenceType.FUNCTIONAL && (
                    <>
                      {competence?.functionalCompetence?.name?.[lang]}
                      {competence?.functionalCompetence?.grade != null && (
                        <span className="ml-2">
                          <PersonalGradingBadge
                            grade={competence.functionalCompetence.grade}
                          />
                        </span>
                      )}
                    </>
                  )}
                </dd>
              </div>
            )}
            {competence.relatedType !== CompetenceType.SPECIFIC && (
              <div className="sm:col-span-3">
                <dt className="text-sm font-medium text-gray-500">
                  {t("fields.definition")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 italic">
                  {competence.relatedType === CompetenceType.GENERIC &&
                    competence?.genericCompetence?.definition?.[lang]}
                  {competence.relatedType === CompetenceType.FUNCTIONAL &&
                    competence?.functionalCompetence?.definition?.[lang]}
                </dd>
              </div>
            )}
            {competence.relatedType === CompetenceType.FUNCTIONAL && (
              <div className="sm:col-span-3">
                <dt className="text-sm font-medium text-gray-500">
                  {tCommon("behaviors")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 italic">
                  <SafeHTMLText
                    html={
                      competence?.functionalCompetence?.grade ===
                      PersonalGrading.master
                        ? (competence?.functionalCompetence?.masterBehaviors?.[
                            lang
                          ] ?? "")
                        : (competence?.functionalCompetence?.behaviors?.[
                            lang
                          ] ?? "")
                    }
                  />
                </dd>
              </div>
            )}
            <div className="sm:col-span-3">
              <dt className="text-sm font-medium text-gray-500">
                {t("fields.description")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {competence.description}
              </dd>
            </div>
            {competence.comments && (
              <div className="sm:col-span-3">
                <dt className="text-sm font-medium text-gray-500">
                  {t("fields.comments")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {competence.comments}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
};

export default CompetenceMeta;
