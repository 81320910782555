import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { PuzzlePieceIcon } from "@heroicons/react/24/outline";

import InfoBanner from "../../../../components/banners/InfoBanner";
import WarningBanner from "../../../../components/banners/WarningBanner";
import CompetencesTable from "../../../../components/competences/CompetencesTable";
import EmptyCompetencesList from "../../../../components/competences/EmptyCompetencesList";
import SubSectionHeader from "../../../../components/headings/SubSectionHeader";
import { ActionMode } from "../../../../models/Actions";
import {
  Employee,
  PersonalGrading
} from "../../../../models/Employee/Employee";
import { Phase } from "../../../../models/Form/BaseForm";
import { openModal } from "../../../../redux/currentApp/slice";
import { selectCurrentDocumentCompetences } from "../../../../redux/currentDocument/competences/selectors";
import {
  selectCurrentDocument,
  selectCurrentDocumentMode,
  selectCurrentDocumentPhase,
  selectShowManagerEvaluation
} from "../../../../redux/currentDocument/selectors";
import { selectCurrentUser } from "../../../../redux/currentUser/selectors";
import { competenceLinks } from "../../../../utils/links";
import GlobalCompetenceEvaluation from "../form/GlobalCompetenceEvaluation";

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  document: selectCurrentDocument,
  competences: selectCurrentDocumentCompetences,
  mode: selectCurrentDocumentMode,
  showManagerEvaluation: selectShowManagerEvaluation,
  currentPhase: selectCurrentDocumentPhase
});

const mapDispatchToProps = {
  openModal
};

interface OwnProps {
  documentId: string;
  employee: Employee;
  isOwner: boolean;
}

const PerformanceCompetences: FC<OwnProps & ReduxProps> = (
  props
): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "competences"
  });
  const {
    currentUser,
    document,
    documentId,
    employee,
    isOwner,
    competences = [],
    mode,
    showManagerEvaluation,
    currentPhase,
    openModal
  } = props;
  const { relatedManager } = document!;
  const isManager = employee._id === relatedManager._id;
  const currentGrade = document?.personalGrading ?? PersonalGrading.starter;
  const canSeeGlobalCompetence = isOwner
    ? document?.managerValidationOn != null
    : true;

  const openAddNewCompetenceModal = () => {
    openModal({
      modal: {
        component: "AddCompetenceModal",
        props: {
          employeeId: employee._id,
          documentId
        }
      }
    });
  };

  const hasMasterCompetences =
    document?.personalGrading != null
      ? document.personalGrading === PersonalGrading.master
        ? competences
            .filter((competence) => competence.functionalCompetence != null)
            .some(
              (competence) =>
                competence?.functionalCompetence?.grade ===
                PersonalGrading.master
            )
        : true
      : true;

  return (
    <div className="my-10 mx-auto max-w-7xl">
      <SubSectionHeader
        title={t("title")}
        link={
          currentPhase === Phase.target
            ? competenceLinks?.[employee?.lang ?? "fr"]
            : undefined
        }
        linkLabel={t("infoLinkLabel")}
        icon={
          <PuzzlePieceIcon
            className="h-6 w-6 text-orange-500"
            aria-hidden="true"
          />
        }
        competenceScore={
          showManagerEvaluation
            ? document?.evaluation?.globalCompetence?.score
            : undefined
        }
        competenceScoreLabel={
          document?.evaluation?.globalCompetence?.score?.name?.[employee.lang!]
        }
        actionLabel={t("actions.add")}
        onActionClick={
          currentPhase === Phase.target && mode === ActionMode.EDIT
            ? () => {
                openAddNewCompetenceModal();
              }
            : undefined
        }
      />
      <div className="my-5">
        <InfoBanner
          title={t(`infoBanner.${currentPhase}.title`)}
          message={t(`infoBanner.${currentPhase}.message`)}
          isHtml
        />
      </div>
      {currentPhase === Phase.target &&
        currentGrade === PersonalGrading.master && (
          <div className="mt-3 mb-5">
            <WarningBanner
              message={t(
                `infoBanner.target.master.${isManager ? "manager" : "employee"}`
              )}
              isHtml
            />
          </div>
        )}
      {competences.length > 0 && !hasMasterCompetences && (
        <div className="mt-3 mb-5">
          <WarningBanner
            message={t("validation.mustSelectMasterCompetences")}
            isHtml
          />
        </div>
      )}
      {document?.relatedPhase === Phase.review &&
        mode != null &&
        document?.relatedFunction?.scoreObjectives &&
        document?.relatedFunction?.scoreCompetences &&
        showManagerEvaluation &&
        canSeeGlobalCompetence && (
          <GlobalCompetenceEvaluation
            document={document}
            canEdit={isManager || (currentUser?.isAdmin ?? false)}
            mode={mode}
          />
        )}

      <CompetencesTable
        documentId={documentId}
        competences={competences}
        lang={employee.lang}
        currentPhase={currentPhase ?? Phase.target}
        showManagerEvaluation={showManagerEvaluation}
      />
      {competences?.length === 0 && (
        <EmptyCompetencesList
          onClick={
            currentPhase === Phase.target && mode === ActionMode.EDIT
              ? () => {
                  openAddNewCompetenceModal();
                }
              : undefined
          }
        />
      )}
    </div>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(PerformanceCompetences);
