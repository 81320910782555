import { FC, ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";

import SubSectionHeader from "../../components/headings/SubSectionHeader";
import TeamlistTable from "../../components/teamlist/TeamlistTable";
import { Employee } from "../../models/Employee/Employee";
import { BaseForm } from "../../models/Form/BaseForm";
import { Cycle } from "../../models/Form/Cycle";
import { prStatusLinks, statusLinks } from "../../utils/links";

type OwnProps = {
  currentCycle: Cycle;
  employee: Employee;
  documents: BaseForm[];
};

const ReviewTeamlist: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { documents, currentCycle, employee } = props;

  const directReports = useMemo(() => {
    return documents?.filter(
      (item) => item.relatedEmployer.identifiant !== 999999
    );
  }, [documents]);

  // Create two arrays of documents:
  // - validatedDocuments: documents that have been validated
  // - nonValidatedDocuments: documents that have not been validated yet
  // scheduled
  const [validatedDocuments, nonValidatedDocuments] = useMemo(() => {
    return directReports?.reduce(
      (acc: [BaseForm[], BaseForm[]], item: BaseForm) => {
        if (item.relatedState.ukey === 3) {
          acc[0].push(item); // validatedDocuments
        } else {
          acc[1].push(item); // nonValidatedDocuments
        }
        return acc;
      },
      [[], []]
    );
  }, [directReports]);

  return (
    <div>
      {/* My direct reports */}
      <div className="border-t border-white/10">
        <div className="pt-4 px-4 sm:px-6 lg:px-8">
          <SubSectionHeader
            title={t("teamlist.nonValidated", {
              count: nonValidatedDocuments?.length ?? 0
            })}
            link={prStatusLinks?.[employee?.lang ?? "fr"]}
            linkLabel={t("myPerformance.infoLinkLabel")}
          />
        </div>
        <div className="mb-6 px-4 sm:px-6 lg:px-8">
          <TeamlistTable teamlist={nonValidatedDocuments} />
        </div>
      </div>

      {/* Validated documents */}
      {validatedDocuments?.length ? (
        <div className="border-t border-white/10 pt-11 mb-6">
          <h2 className="px-4 text-base font-semibold leading-7 sm:px-6 lg:px-8">
            {t("teamlist.validated", {
              count: validatedDocuments?.length ?? 0
            })}
          </h2>
          <div className="mt-6 px-4 sm:px-6 lg:px-8">
            <TeamlistTable teamlist={validatedDocuments} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ReviewTeamlist;
