import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { CubeTransparentIcon } from "@heroicons/react/24/outline";

import InfoBanner from "../../../../components/banners/InfoBanner";
import DevActionsTable from "../../../../components/devActions/DevActionsTable";
import EmptyDevActionsList from "../../../../components/devActions/EmptyDevActionsList";
import SubSectionHeader from "../../../../components/headings/SubSectionHeader";
import { ActionMode } from "../../../../models/Actions";
import { Employee } from "../../../../models/Employee/Employee";
import { Phase } from "../../../../models/Form/BaseForm";
import { openModal } from "../../../../redux/currentApp/slice";
import { selectCurrentDocumentCompetences } from "../../../../redux/currentDocument/competences/selectors";
import { selectCurrentDocumentDevActions } from "../../../../redux/currentDocument/devActions/selectors";
import { selectCurrentDocumentObjectives } from "../../../../redux/currentDocument/objectives/selectors";
import {
  selectCurrentDocumentMode,
  selectCurrentDocumentPhase,
  selectShowManagerEvaluation
} from "../../../../redux/currentDocument/selectors";
import { selectCurrentUser } from "../../../../redux/currentUser/selectors";
import { developmentActionLinks } from "../../../../utils/links";

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  objectives: selectCurrentDocumentObjectives,
  competences: selectCurrentDocumentCompetences,
  developmentActions: selectCurrentDocumentDevActions,
  mode: selectCurrentDocumentMode,
  showManagerEvaluation: selectShowManagerEvaluation,
  currentPhase: selectCurrentDocumentPhase
});

const mapDispatchToProps = {
  openModal
};

interface OwnProps {
  documentId: string;
  employee: Employee;
}

const PerformanceDevActions: FC<OwnProps & ReduxProps> = (
  props
): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "devActions"
  });
  const {
    currentUser,
    documentId,
    developmentActions = [],
    objectives,
    competences,
    employee,
    mode,
    showManagerEvaluation,
    currentPhase,
    openModal
  } = props;

  const openAddNewDevActionModal = () => {
    openModal({
      modal: {
        component: "AddDevActionModal",
        props: {
          documentId,
          objectives,
          competences
        }
      }
    });
  };

  return (
    <div className="my-10 mx-auto max-w-7xl">
      <SubSectionHeader
        title={t("title")}
        link={
          currentPhase === Phase.target
            ? developmentActionLinks?.[employee?.lang ?? "fr"]
            : undefined
        }
        linkLabel={t("infoLinkLabel")}
        icon={
          <CubeTransparentIcon
            className="h-6 w-6 text-violet-500"
            aria-hidden="true"
          />
        }
        actionLabel={t("actions.add")}
        onActionClick={
          currentPhase === Phase.target && mode === ActionMode.EDIT
            ? () => {
                openAddNewDevActionModal();
              }
            : undefined
        }
      />
      <div className="my-5">
        <InfoBanner
          title={t(`infoBanner.${currentPhase}.title`)}
          message={t(`infoBanner.${currentPhase}.message`)}
          isHtml
        />
      </div>
      <DevActionsTable
        documentId={documentId}
        devActions={developmentActions}
        lang={employee.lang}
        currentPhase={currentPhase ?? Phase.target}
        showManagerEvaluation={showManagerEvaluation}
      />
      {developmentActions?.length === 0 && (
        <EmptyDevActionsList
          onClick={
            currentPhase === Phase.target && mode === ActionMode.EDIT
              ? () => openAddNewDevActionModal()
              : undefined
          }
        />
      )}
    </div>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(PerformanceDevActions);
