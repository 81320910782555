import { FC, ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as Yup from "yup";

import { BaseForm, FormRole } from "../../models/Form/BaseForm";
import { SupportedLanguages } from "../../models/LangField";
import { NotificationType } from "../../models/Notification";
import { showNotification } from "../../redux/currentApp/slice";
import { selectCurrentDocumentObjectives } from "../../redux/currentDocument/objectives/selectors";
import {
  isLoadingSetDocumentConfirmationComment,
  selectSetDocumentConfirmationCommentError,
  selectSetDocumentConfirmationCommentStatus
} from "../../redux/currentDocument/selectors";
import {
  clearSetDocumentConfirmationCommentStatus,
  setDocumentConfirmationComment
} from "../../redux/currentDocument/slice";
import { parseISODateStr } from "../../utils/dates";
import { classNames } from "../../utils/styles";
import ErrorBanner from "../banners/ErrorBanner";
import WarningBanner from "../banners/WarningBanner";
import InputTextArea from "../forms/InputTextArea";
import Spinner from "../loaders/Spinner";
import Modal from "../modal/Modal";
import SafeHTMLText from "../texts/SafeHTMLText";
import DocumentEvaluationScores from "./DocumentEvaluationScores";
import { Formik } from "formik";
import { Form } from "formik";
import InputRichText from "../forms/InputRichText";
import { ClassicEditor } from "ckeditor5";
import { UserRole } from "../../models/User";

const mapStateToProps = createStructuredSelector({
  objectives: selectCurrentDocumentObjectives,
  isLoading: isLoadingSetDocumentConfirmationComment,
  status: selectSetDocumentConfirmationCommentStatus,
  formError: selectSetDocumentConfirmationCommentError
});

const mapDispatchToProps = {
  addConfirmationComment: setDocumentConfirmationComment,
  clearStatus: clearSetDocumentConfirmationCommentStatus,
  notify: showNotification
};

interface OwnProps {
  document: BaseForm;
  role: FormRole;
  lang: SupportedLanguages;
  closeModal: () => void;
}

type Props = OwnProps & ReduxProps;

const DocumentValidationResultsModal: FC<Props> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "form.evaluation"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const editorRef = useRef<ClassicEditor>(null);
  const {
    document,
    objectives,
    role,
    lang,
    isLoading,
    status,
    formError,
    addConfirmationComment,
    clearStatus,
    notify,
    closeModal
  } = props;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const otherRole =
    role === FormRole.employee ? FormRole.manager : FormRole.employee;

  useEffect(() => {
    if (isLoading) return;

    if (formError) {
      setErrorMessage(formError);
    }

    if (status === "success") {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.validationSucess"),
        message: t("validation.messages.success")
      });

      // Cleanup
      clearStatus();
      closeModal();
    }
  }, [
    isLoading,
    status,
    formError,
    clearStatus,
    document,
    notify,
    closeModal,
    t,
    tCommon
  ]);

  const globalObjectiveScore = useMemo(() => {
    return (
      objectives?.reduce((acc, objective) => {
        const weight = objective?.weight ?? 0;
        const scoreWeight = objective.evaluation?.manager?.scoreWeight ?? 0;
        return acc + (weight * scoreWeight) / 100;
      }, 0) ?? 0
    );
  }, [objectives]);

  const globalCompetenceScore = useMemo(() => {
    return document?.evaluation?.globalCompetence?.score;
  }, [document]);

  const initialState = {
    comment: document.evaluation?.[role].confirmationComment ?? ""
  };

  const validationSchema = Yup.object({
    comment: Yup.string()
  });

  return (
    <Modal closeModal={closeModal}>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          addConfirmationComment({
            formUUID: document.formUUID,
            role:
              role === FormRole.employee ? UserRole.EMPLOYEE : UserRole.MANAGER,
            comment: values.comment
          });
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <div className="sm:flex sm:items-start">
              <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {t("results.title")}
                  </h2>
                  <div>
                    <SafeHTMLText
                      html={t(`results.subtitle.${role}`, {
                        year: document?.relatedCycle.year
                      })}
                      className="mt-1 mb-2 text-sm leading-6 text-grey-900"
                    />
                    <DocumentEvaluationScores
                      lang={lang}
                      objectiveScore={globalObjectiveScore}
                      competenceScore={globalCompetenceScore}
                    />
                    <div className="mt-5">
                      <WarningBanner
                        message={t("results.confirmationComment.note")}
                        isHtml
                      />
                    </div>

                    <div className="mt-5">
                      <InputRichText
                        innerRef={editorRef}
                        label={t("results.confirmationComment.label")}
                        name="comment"
                        initialValue={initialState.comment}
                        onChange={(value: string) => {
                          setFieldValue("comment", value);
                        }}
                        helperText={t("results.confirmationComment.helper")}
                      />
                    </div>
                  </div>
                  {errorMessage != null && (
                    <div className="mt-5">
                      <ErrorBanner message={errorMessage} />
                    </div>
                  )}
                  {document.evaluation?.[otherRole].confirmationComment && (
                    <div className="mt-5 pt-5 border-t border-gray-900/10">
                      <h3 className="text-base font-semibold leading-7 text-gray-900">
                        {t(
                          `results.confirmationComment.otherRole.${otherRole}`
                        )}
                      </h3>
                      <SafeHTMLText
                        html={
                          document.evaluation?.[otherRole].confirmationComment
                        }
                        className="mt-1 mb-2 text-sm leading-6 text-grey-900"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className={classNames(
                  "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto",
                  isLoading ? "cursor-not-allowed opacity-50 ml-2" : ""
                )}
                disabled={isLoading || isSubmitting}
              >
                {isLoading || isSubmitting ? (
                  <Spinner size="small" />
                ) : (
                  tCommon("actions.save")
                )}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => closeModal()}
              >
                {tCommon("actions.cancel")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(DocumentValidationResultsModal);
