import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { HandThumbUpIcon, PencilIcon } from "@heroicons/react/24/outline";

import InfoBanner from "../../../../components/banners/InfoBanner";
import SubSectionHeader from "../../../../components/headings/SubSectionHeader";
import { ActionMode } from "../../../../models/Actions";
import {
  BaseForm,
  Phase,
  RawBaseFormConclusion
} from "../../../../models/Form/BaseForm";
import { openModal } from "../../../../redux/currentApp/slice";
import { selectCurrentDocumentMode } from "../../../../redux/currentDocument/selectors";
import PerformanceConclusionDetail from "./PerformanceConclusionDetail";
import PerformanceConclusionEvaluation from "./PerformanceConclusionEvaluation";

const mapStateToProps = createStructuredSelector({
  mode: selectCurrentDocumentMode
});

const mapDispatchToProps = {
  openModal
};

interface OwnProps {
  document: BaseForm;
  isOwner: boolean;
  isManager: boolean;
  isEmployee: boolean;
}

const PerformanceConclusion: FC<OwnProps & ReduxProps> = (
  props
): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "conclusion"
  });
  const {
    document,
    isOwner,
    isManager,
    isEmployee,
    mode = ActionMode.READ_ONLY,
    openModal
  } = props;
  const currentPhase = document.relatedPhase;
  const conclusion: RawBaseFormConclusion = {
    linkedFile: document?.linkedFile,
    employeeComments: document?.employeeComments,
    managerComments: document?.managerComments
  };

  const openEditConclusionModal = () => {
    openModal({
      modal: {
        component: "EditConclusionModal",
        props: {
          documentId: document.formUUID,
          conclusion,
          isOwner,
          isManager,
          isEmployee
        }
      }
    });
  };

  return (
    <div className="my-10 mx-auto max-w-7xl">
      <SubSectionHeader
        title={t("title")}
        icon={
          <HandThumbUpIcon
            className="h-6 w-6 text-red-400"
            aria-hidden="true"
          />
        }
        actionLabel={t("actions.edit")}
        onActionClick={
          currentPhase === Phase.target &&
          (isOwner || isManager) &&
          mode === ActionMode.EDIT
            ? () => {
                openEditConclusionModal();
              }
            : undefined
        }
        actionIcon={
          <PencilIcon className="-ml-1.5 h-5 w-5" aria-hidden="true" />
        }
      />
      <div className="my-5">
        <InfoBanner
          title={t("infoBanner.title")}
          message={t("infoBanner.message")}
          isHtml
        />
      </div>
      {document.relatedPhase === Phase.review && (
        <div className="my-5">
          <PerformanceConclusionEvaluation
            document={document}
            isManager={isManager}
            isEmployee={isEmployee}
            mode={mode}
          />
        </div>
      )}
      <PerformanceConclusionDetail conclusion={conclusion} />
    </div>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(PerformanceConclusion);
