import { Form, Formik } from "formik";
import { FC, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";
import * as Yup from "yup";

import {
  CompetenceType,
  FunctionalCompetence,
  GenericCompetence
} from "../../models/Form/BaseCompetence";
import { NotificationType } from "../../models/Notification";
import { showNotification } from "../../redux/currentApp/slice";
import {
  isLoadingCreateDocumentCompetence,
  selectCreateDocumentCompetenceError,
  selectCreateDocumentCompetenceStatus
} from "../../redux/currentDocument/competences/selectors";
import {
  clearCreateDocumentCompetenceStatus,
  createDocumentCompetence
} from "../../redux/currentDocument/competences/slice";
import {
  selectCurrentDocumentCycle,
  selectCurrentDocumentGrade
} from "../../redux/currentDocument/selectors";
import { selectCurrentEmployeeLang } from "../../redux/currentEmployee/selectors";
import { classNames } from "../../utils/styles";
import ErrorBanner from "../banners/ErrorBanner";
import InputText from "../forms/InputText";
import InputTextArea from "../forms/InputTextArea";
import Spinner from "../loaders/Spinner";
import Modal from "../modal/Modal";
import CompetenceTypeSelector from "../selectors/CompetenceTypeSelector";
import FunctionalCompetenceSelector from "../selectors/FunctionalCompetenceSelector";
import GenericCompetenceSelector from "../selectors/GenericCompetenceSelector";

const mapStateToProps = createStructuredSelector({
  isLoading: isLoadingCreateDocumentCompetence,
  status: selectCreateDocumentCompetenceStatus,
  formError: selectCreateDocumentCompetenceError,
  lang: selectCurrentEmployeeLang,
  cycle: selectCurrentDocumentCycle,
  grade: selectCurrentDocumentGrade
});

const mapDispatchToProps = {
  createCompetence: createDocumentCompetence,
  clearStatus: clearCreateDocumentCompetenceStatus,
  notify: showNotification
};

interface OwnProps {
  documentId: string;
  closeModal: () => void;
}

type Props = OwnProps & ReduxProps;

const AddCompetenceModal: FC<Props> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "competences"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const {
    documentId,
    isLoading,
    status,
    formError,
    lang,
    cycle,
    grade,
    createCompetence,
    clearStatus,
    notify,
    closeModal
  } = props;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [competenceType, setCompetenceType] = useState<CompetenceType | null>(
    null
  );
  const [genericCompetence, setGenericCompetence] =
    useState<GenericCompetence | null>(null);
  const [functionalCompetence, setFunctionalCompetence] =
    useState<FunctionalCompetence | null>(null);

  useEffect(() => {
    if (isLoading) return;

    if (formError) {
      setErrorMessage(formError);
    }

    if (status === "success") {
      notify({
        type: NotificationType.SUCCESS,
        title: tCommon("notifications.createSuccess"),
        message: t("create.messages.success")
      });

      // Cleanup
      closeModal();
      clearStatus();
    }
  }, [
    isLoading,
    status,
    formError,
    closeModal,
    clearStatus,
    notify,
    t,
    tCommon
  ]);

  // Component state
  const initialState = {
    title: "",
    description: ""
  };

  const validationSchema = Yup.object({
    title: Yup.string()
      .max(140, "Must be 140 characters or less")
      .required(t("form.errors.title")),
    description: Yup.string()
      .max(5000, "Must be 5000 characters or less")
      .required(t("form.errors.description"))
  });

  return (
    <Modal closeModal={closeModal}>
      <Formik
        initialValues={initialState}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          if (!competenceType) {
            setErrorMessage(t("form.errors.competenceType"));
            return;
          }

          createCompetence({
            formUUID: documentId,
            data: {
              relatedType: competenceType,
              genericCompetence: genericCompetence?._id,
              functionalCompetence: functionalCompetence?._id,
              ...values
            }
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="sm:flex sm:items-start">
              <div className="space-y-12 w-full">
                <div className="border-b border-gray-900/10 pb-12">
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    {t("create.title")}
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    {t("create.subtitle")}
                  </p>
                  <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    {/* Competence type selector */}
                    <div className="col-span-full col-start-1">
                      <label
                        htmlFor="objectiveType"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        {t("fields.type")}
                      </label>
                      <CompetenceTypeSelector
                        onSelectItem={(item) => setCompetenceType(item)}
                        exclude={
                          cycle?.year && cycle.year > 2024
                            ? [CompetenceType.GENERIC]
                            : []
                        }
                      />
                    </div>
                    {competenceType != null &&
                      competenceType !== CompetenceType.SPECIFIC && (
                        <div className="col-span-full">
                          <label
                            htmlFor="objectiveType"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            {t("fields.competence")}
                          </label>
                          {competenceType === CompetenceType.GENERIC ? (
                            <GenericCompetenceSelector
                              lang={lang!}
                              onSelectItem={setGenericCompetence}
                              showDefinition
                            />
                          ) : competenceType === CompetenceType.FUNCTIONAL ? (
                            <FunctionalCompetenceSelector
                              lang={lang!}
                              onSelectItem={setFunctionalCompetence}
                              grade={grade ?? undefined}
                              showDefinition
                            />
                          ) : null}
                        </div>
                      )}

                    {/* Title */}
                    <div className="col-span-full">
                      <InputText label={t("fields.title")} name="title" />
                    </div>

                    {/* Short description */}
                    <div className="col-span-full">
                      <InputTextArea
                        label={t("fields.description")}
                        name="description"
                        helperText={t("form.helpers.description")}
                      />
                    </div>
                  </div>
                  {errorMessage != null && (
                    <div className="mt-5">
                      <ErrorBanner message={errorMessage} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="submit"
                className={classNames(
                  "inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto",
                  isSubmitting ? "cursor-not-allowed opacity-50 ml-2" : ""
                )}
                disabled={isSubmitting}
              >
                {isSubmitting && <Spinner size="small" />}
                {tCommon("actions.save")}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => closeModal()}
              >
                {tCommon("actions.cancel")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(AddCompetenceModal);
