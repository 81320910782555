import { FC, ReactElement } from "react";

import { PersonalGrading } from "../../models/Employee/Employee";
import { classNames } from "../../utils/styles";

const grades: Record<PersonalGrading, string> = {
  [PersonalGrading.master]: "fill-red-500",
  [PersonalGrading.confirmed]: "fill-yellow-600",
  [PersonalGrading.intermediate]: "fill-blue-500",
  [PersonalGrading.starter]: "fill-green-500"
};

type OwnProps = {
  grade: PersonalGrading;
};

const PersonalGradingBadge: FC<OwnProps> = (props): ReactElement => {
  const { grade } = props;
  const componentClass = grades[grade];

  return (
    <span className="inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
      <svg
        className={classNames("h-1.5 w-1.5", componentClass)}
        viewBox="0 0 6 6"
        aria-hidden="true"
      >
        <circle cx={3} cy={3} r={3} />
      </svg>
      {grade}
    </span>
  );
};

export default PersonalGradingBadge;
