import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";
import { FC, ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { BuildingOfficeIcon } from "@heroicons/react/20/solid";

import NumericBadge from "../../../components/badges/NumericBadge";
import DocumentCoachsList from "../../../components/coach/DocumentCoachsList";
import PersonalGradingBadge from "../../../components/employees/PersonalGradingBadge";
import UserAvatar from "../../../components/users/UserAvatar";
import { useUserCourseRegistrations } from "../../../hooks/integrations/riseUp/userUserCourseRegistrations";
import { ActionMode } from "../../../models/Actions";
import { BaseForm, FormRole, Phase } from "../../../models/Form/BaseForm";
import { HolaCircle } from "../../../models/integrations/HolaSpirit/HolaCircle";
import { HolaRole } from "../../../models/integrations/HolaSpirit/HolaRole";
import { User } from "../../../models/User";
import { classNames } from "../../../utils/styles";
import { getAvatarUrl } from "../../../utils/user";
import PerformanceReviewActions from "./form/PerformanceReviewActions";

type MemberCircle = HolaCircle & { roles: HolaRole[] };

interface OwnProps {
  currentUser: User | undefined;
  document: BaseForm;
  mode: ActionMode;
  role?: FormRole;
  openModal: (modal: any) => void;
}

const PerformanceHeaderNew: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { currentUser, document, role, mode, openModal } = props;
  const {
    formUUID,
    relatedEmployer,
    relatedFunction,
    relatedManager,
    relatedEmployee,
    performanceCoachs,
    personalGrading
  } = document;
  const { name, holaMember } = relatedEmployee;

  const openNotifyModal = () => {
    if (!role) return;

    openModal({
      modal: {
        component: "DocumentNotifyModal",
        props: {
          documentId: formUUID,
          role,
          showWarning:
            document.relatedPhase === Phase.review &&
            role === FormRole.manager &&
            relatedFunction.scoreObjectives &&
            relatedFunction.scoreCompetences
        }
      }
    });
  };

  // Group holaspirit roles by circle
  const holaMemberCircles: MemberCircle[] =
    holaMember?.decisionMakerInCircles?.map((circle) => {
      return {
        ...circle,
        roles:
          holaMember?.memberInRoles?.filter(
            (role) => role.parentCircle === circle._id
          ) ?? []
      };
    }) ?? [];

  const { data: registrations = [] } = useUserCourseRegistrations(
    relatedEmployee?.riseupUser?._id
  );

  const registrationsByYear = useMemo(() => {
    return mapValues(
      groupBy(
        [...registrations].sort(
          (a, b) =>
            new Date(b.subscribedate).getTime() -
            new Date(a.subscribedate).getTime()
        ),
        (registration) => {
          return new Date(registration.subscribedate).getFullYear();
        }
      ),
      (group) => group
    );
  }, [registrations]);
  const sortedYears = Object.keys(registrationsByYear).sort(
    (a, b) => parseInt(b) - parseInt(a)
  );

  const openCourseRegistrationHistoryModal = (year: string) => {
    openModal({
      modal: {
        component: "CourseRegistrationHistoryModal",
        props: {
          year,
          registrations: registrationsByYear[year]
        }
      }
    });
  };

  return (
    <div className="w-64 flex-shrink-0">
      <div className="py-6 pl-4 pr-6 sm:pl-6 lg:pl-8 xl:pl-0">
        <div className="flex items-center justify-between bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
          <div className="flex-1 space-y-6">
            <div className="space-y-6 block">
              {/* Profile */}
              <UserAvatar
                name={name}
                pictureUrl={getAvatarUrl(holaMember)}
                size={12}
                additionalText={relatedFunction.description.fr}
                personalGrading={personalGrading ?? undefined}
                showName
              />

              <dl>
                <div className="flex text-xs font-medium">
                  <dt className="text-gray-500">
                    <BuildingOfficeIcon
                      className="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                  </dt>
                  <dd className="text-gray-900">{relatedEmployer.name}</dd>
                </div>
              </dl>

              {/* Action buttons */}
              <div className="flex flex-col flex-col">
                <button
                  type="button"
                  className={classNames(
                    "inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 xl:w-full",
                    mode === ActionMode.READ_ONLY
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  )}
                  onClick={openNotifyModal}
                  disabled={role == null || mode === ActionMode.READ_ONLY}
                >
                  {t("form.notify.action")}
                </button>
              </div>

              {/* Performance Review actions */}
              {currentUser &&
                role &&
                document.relatedPhase === Phase.review && (
                  <PerformanceReviewActions
                    currentUser={currentUser}
                    document={document}
                    role={role}
                    openModal={openModal}
                  />
                )}

              {/* Mgmt details */}
              <div>
                <h3 className="font-medium text-sm text-indigo-600">
                  Lead link / Manager
                </h3>
                <dl className="mt-2">
                  <div className="flex py-3 text-sm font-medium">
                    <UserAvatar
                      name={relatedManager.name}
                      pictureUrl={getAvatarUrl(relatedManager?.holaMember)}
                      showName
                    />
                  </div>
                </dl>
              </div>

              {/* Coachs details */}
              {document.relatedPhase === Phase.target &&
                mode === ActionMode.EDIT && (
                  <div>
                    <h3 className="font-medium text-sm text-indigo-600">
                      Performance Coachs
                    </h3>
                    <dl className="mt-2">
                      <DocumentCoachsList
                        documentId={formUUID}
                        performanceCoachs={performanceCoachs}
                        canAddCoach={
                          performanceCoachs.length < 2 &&
                          role === FormRole.employee
                        }
                      />
                    </dl>
                  </div>
                )}

              {/* Holaspirit details */}
              <div>
                <h3 className="font-medium text-sm text-indigo-600">
                  Holaspirit
                </h3>
                <dl className="mt-2">
                  <div className="py-3 text-sm">
                    <dd className="text-grey-900 line-clamp-4">
                      {holaMemberCircles?.map((circle) => (
                        <div
                          key={circle._id}
                          className="flex items-center justify-between"
                        >
                          <span className="mr-2">{circle.name}</span>
                          <NumericBadge
                            value={circle.roles.length}
                            color="grey"
                            tooltip={
                              circle.roles
                                ?.map((role) => role.name)
                                .join(", ") ?? "N/A"
                            }
                          />
                        </div>
                      ))}
                    </dd>
                  </div>
                </dl>
              </div>

              {/* Rise Up details */}
              <div>
                <h3 className="font-medium text-sm text-indigo-600">Rise Up</h3>
                <dl className="mt-2">
                  <div className="py-3 text-sm">
                    <dd className="text-grey-900 line-clamp-4">
                      {sortedYears?.map((year) => (
                        <div
                          key={`rise-up-${year}`}
                          className="flex items-center justify-between mb-2"
                        >
                          <span className="mr-2">{year}</span>
                          <span
                            className="cursor-pointer"
                            onClick={() =>
                              openCourseRegistrationHistoryModal(year)
                            }
                          >
                            <NumericBadge
                              value={registrationsByYear?.[year].length}
                              color="grey"
                            />
                          </span>
                        </div>
                      ))}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceHeaderNew;
