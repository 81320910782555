import { FC, Fragment, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition
} from "@headlessui/react";
import {
  CalendarIcon,
  EllipsisHorizontalIcon
} from "@heroicons/react/20/solid";

import { Employee } from "../../models/Employee/Employee";
import { FormMeeting } from "../../models/Form/FormMeeting";
import { formatISODateStr } from "../../utils/dates";
import { getFullName } from "../../utils/strings";
import { classNames } from "../../utils/styles";
import UsersList from "../users/UsersList";
import MeetingTypeIcon from "./MeetingTypeIcon";

type OwnProps = {
  meeting: FormMeeting;
  canEdit: boolean;
  openEditModal: (meeting: FormMeeting) => void;
  openDeleteModal: (meeting: FormMeeting) => void;
};

const MeetingListItem: FC<OwnProps> = (props): ReactElement => {
  const { t: tCommon } = useTranslation("translation", { keyPrefix: "common" });
  const { meeting, canEdit = false, openEditModal, openDeleteModal } = props;

  const meetingParticipants: Partial<Employee>[] = [
    ...(meeting.participants as Partial<Employee>[]),
    meeting.createdBy as Partial<Employee>
  ];

  return (
    <li key={meeting._id} className="relative flex space-x-6 py-6 xl:static">
      <div className="flex-initial">
        <UsersList users={meetingParticipants} size={10} />
      </div>
      <div className="flex-auto">
        <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0">
          <MeetingTypeIcon type={meeting.meetingType} />
          {meetingParticipants.map((p) => getFullName(p.name!)).join(", ")}
        </h3>
        <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
          <div className="flex items-start space-x-3">
            <dt className="mt-0.5">
              <span className="sr-only">Date</span>
              <CalendarIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </dt>
            <dd>
              <time dateTime={meeting.meetingDate}>
                {formatISODateStr(meeting.meetingDate, "MMMM do, yyyy h:mm a")}
              </time>
            </dd>
          </div>
        </dl>
        <div className="mt-2 text-sm text-gray-800">{meeting.body}</div>
      </div>

      {canEdit && (
        <Menu
          as="div"
          className="absolute right-0 top-6 xl:relative xl:right-auto xl:top-auto xl:self-center"
        >
          <div>
            <MenuButton className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
              <span className="sr-only">Open options</span>
              <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
            </MenuButton>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <MenuItem>
                  {({ focus }) => (
                    <button
                      type="button"
                      onClick={() => openEditModal(meeting)}
                      className={classNames(
                        focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block w-full px-4 py-2 text-sm"
                      )}
                    >
                      {tCommon("actions.edit")}
                    </button>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ focus }) => (
                    <button
                      type="button"
                      onClick={() => openDeleteModal(meeting)}
                      className={classNames(
                        focus ? "bg-gray-100 text-gray-900" : "text-red-600",
                        "block w-full px-4 py-2 text-sm"
                      )}
                    >
                      {tCommon("actions.delete")}
                    </button>
                  )}
                </MenuItem>
              </div>
            </MenuItems>
          </Transition>
        </Menu>
      )}
    </li>
  );
};

export default MeetingListItem;
