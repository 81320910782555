import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import ActivityLogsList from "../../../../components/activities/ActivityLogsList";
import NotesList from "../../../../components/notes/NotesList";
import { ActionMode } from "../../../../models/Actions";
import { BaseForm, Phase } from "../../../../models/Form/BaseForm";
import { BaseValue } from "../../../../models/Form/BaseValue";
import { ActivityLogResourceType } from "../../../../models/Logs/ActivityLog";
import { NotificationConfig } from "../../../../models/Notification";
import { User } from "../../../../models/User";
import ValueEvaluations from "./ValueEvaluations";
import ValueHeader from "./ValueHeader";
import ValueMeta from "./ValueMeta";

type OwnProps = {
  currentUser: User;
  employeeId: string;
  document: BaseForm;
  value: BaseValue;
  mode?: ActionMode;
  notify: (notification: NotificationConfig) => void;
};

const PerformanceValueDetail: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "values"
  });
  const {
    currentUser,
    employeeId,
    document,
    value,
    mode = ActionMode.READ_ONLY,
    notify
  } = props;
  const { formUUID: documentId, relatedEmployee, relatedManager } = document;
  const isManager = employeeId === relatedManager._id;
  const isEmployee = employeeId === relatedEmployee._id;
  const currentPhase = document.relatedPhase;

  return (
    <main className="py-10">
      <div className="mx-auto max-w-3xl md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
        <ValueHeader value={value} />
      </div>

      <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
          <ValueMeta value={value} lang={currentUser.lang} />

          {document.relatedPhase === Phase.review && (
            <ValueEvaluations
              document={document}
              value={value}
              isManager={isManager || (currentUser.isAdmin && !isEmployee)}
              isEmployee={isEmployee}
              mode={mode}
            />
          )}

          {/* Comments */}
          <NotesList
            currentUser={currentUser}
            documentId={documentId}
            employeeId={employeeId}
            resourceType="value"
            resourceId={value._id}
            mode={currentPhase === Phase.review ? ActionMode.READ_ONLY : mode}
            notify={notify}
          />
        </div>

        {currentPhase === Phase.target && (
          <div>
            <ActivityLogsList
              documentId={documentId}
              resourceType={ActivityLogResourceType.VALUE}
              resourceId={value._id}
            />
          </div>
        )}
      </div>
    </main>
  );
};

export default PerformanceValueDetail;
