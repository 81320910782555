import { FC, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";

import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

import { ActionMode } from "../../../models/Actions";
import { RiseUpCourse } from "../../../models/integrations/RiseUp/RiseUpCourse";
import ActionButton from "../../buttons/ActionButton";
import DefaultButton from "../../buttons/DefaultButton";
import RiseUpCourseSelector from "../../selectors/RiseUpCourseSelector";
import CourseItem from "./CourseItem";

type OwnProps = {
  courses: RiseUpCourse[];
  mode: ActionMode;
  onLinkCourse?: (course: RiseUpCourse) => void;
  onUnlinkCourse?: (course: RiseUpCourse) => void;
};

const CourseCatalog: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const { courses, mode, onLinkCourse, onUnlinkCourse } = props;

  const [isEditing, setIsEditing] = useState(false);

  return (
    <section aria-labelledby="courses-catalog">
      <div className="bg-white shadow sm:rounded-lg py-5 sm:px-6 border-b border-gray-200">
        <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h2
              id="courses-catalog-title"
              className="text-lg font-medium text-gray-900"
            >
              {t("riseUp.findCourses")}
            </h2>
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            <ActionButton
              label={t("actions.selectCourse")}
              onClick={() => setIsEditing(true)}
              disabled={
                mode === ActionMode.READ_ONLY || isEditing || !onLinkCourse
              }
              icon={MagnifyingGlassIcon}
            />
          </div>
        </div>
        {isEditing && onLinkCourse && (
          <div className="flex items-center space-between mt-3">
            <div className="w-full mr-2">
              <RiseUpCourseSelector
                onSelectItem={(item) => {
                  if (item) onLinkCourse(item);
                }}
              />
            </div>
            <DefaultButton
              label={t("actions.cancel")}
              onClick={() => setIsEditing(false)}
            />
          </div>
        )}
        <dd className="mt-3 text-sm text-gray-900">
          {courses.length ? (
            <ul className="divide-y divide-gray-100">
              {courses.map((course) => (
                <CourseItem
                  key={course._id}
                  course={course}
                  onRemoveItem={onUnlinkCourse}
                />
              ))}
            </ul>
          ) : null}
        </dd>
      </div>
    </section>
  );
};

export default CourseCatalog;
