import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { createStructuredSelector } from "reselect";

import { FireIcon } from "@heroicons/react/24/outline";

import InfoBanner from "../../../../components/banners/InfoBanner";
import SubSectionHeader from "../../../../components/headings/SubSectionHeader";
import ValuesTable from "../../../../components/values/ValuesTable";
import { Employee } from "../../../../models/Employee/Employee";
import { Phase } from "../../../../models/Form/BaseForm";
import { openModal } from "../../../../redux/currentApp/slice";
import {
  selectCurrentDocumentMode,
  selectCurrentDocumentPhase,
  selectShowManagerEvaluation
} from "../../../../redux/currentDocument/selectors";
import { selectCurrentDocumentValues } from "../../../../redux/currentDocument/values/selectors";
import { valueLinks } from "../../../../utils/links";

const mapStateToProps = createStructuredSelector({
  values: selectCurrentDocumentValues,
  mode: selectCurrentDocumentMode,
  showManagerEvaluation: selectShowManagerEvaluation,
  currentPhase: selectCurrentDocumentPhase
});

const mapDispatchToProps = {
  openModal
};

interface OwnProps {
  documentId: string;
  employee: Employee;
}

const PerformanceValues: FC<OwnProps & ReduxProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "values"
  });
  const {
    documentId,
    employee,
    values = [],
    mode,
    showManagerEvaluation,
    currentPhase,
    openModal
  } = props;

  // const openAddValueModal = () => {
  //   openModal({
  //     modal: {
  //       component: "AddValueModal",
  //       props: {
  //         documentId
  //       }
  //     }
  //   });
  // };

  return (
    <div className="my-10 mx-auto max-w-7xl">
      <SubSectionHeader
        title={t("title")}
        link={valueLinks?.[employee?.lang ?? "fr"]}
        linkLabel={t("infoLinkLabel")}
        icon={<FireIcon className="h-6 w-6 text-rose-500" aria-hidden="true" />}
      />
      <div className="my-5">
        <InfoBanner
          title={t(`infoBanner.${currentPhase}.title`)}
          message={t(`infoBanner.${currentPhase}.message`)}
          isHtml
        />
      </div>
      <ValuesTable
        documentId={documentId}
        values={values}
        lang={employee.lang}
        currentPhase={currentPhase ?? Phase.target}
        showManagerEvaluation={showManagerEvaluation}
      />
    </div>
  );
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(PerformanceValues);
