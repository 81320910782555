import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { CompetenceType } from "../../models/Form/BaseCompetence";
import { classNames } from "../../utils/styles";

const statuses: Record<CompetenceType, string> = {
  [CompetenceType.FUNCTIONAL]: "bg-red-50 text-red-700 ring-red-600/10",
  [CompetenceType.GENERIC]: "bg-green-50 text-green-700 ring-green-600/20",
  [CompetenceType.SPECIFIC]: "bg-blue-50 text-blue-700 ring-blue-700/10"
};

type OwnProps = {
  type: CompetenceType;
};

const CompetenceTypeBadge: FC<OwnProps> = (props): ReactElement => {
  const { t: tStatuses } = useTranslation("translation", {
    keyPrefix: "competences"
  });
  const { type } = props;
  const componentClass = statuses[type];

  return (
    <span
      className={classNames(
        "inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset",
        componentClass
      )}
    >
      {tStatuses(`types.${type}`)}
    </span>
  );
};

export default CompetenceTypeBadge;
