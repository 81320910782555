import { HolaCircle } from "../integrations/HolaSpirit/HolaCircle";
import { HolaRole } from "../integrations/HolaSpirit/HolaRole";
import { SupportedLanguages } from "../LangField";
import { PersonName } from "../User";
import { EmployeeFunction } from "./EmployeeFunction";
import { Employer } from "./Employer";

export enum PersonalGrading {
  "starter" = "starter",
  "intermediate" = "intermediate",
  "confirmed" = "confirmed",
  "master" = "master"
}

export interface Employee {
  _id: string;
  name: PersonName;
  email: string;
  lang: SupportedLanguages;
  relatedFunction: EmployeeFunction;
  isManager: boolean;
  avatarUrl?: string;
  employer: Employer;

  // Personal grading
  personalGrading?: PersonalGrading | null;
  optinDate?: Date | null;

  // Free fields
  freeField6?: string;
  freeField7?: string;

  // Integrations
  holaMember?: {
    _id: string;
    avatarUrl?: string;
    decisionMakerInCircles?: HolaCircle[];
    memberInRoles?: HolaRole[];
  };

  riseupUser: {
    _id: string;
  };
}

export type Coach = Pick<Employee, "_id" | "name" | "isManager" | "holaMember">;
