import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

import { Phase } from '../../models/Form/BaseForm';
import { RequestStatus } from '../../models/Request';
import { RootState } from '../../store/store';
import {
  addDocumentEvaluation, addGlobalCompetenceEvaluation, removeDocumentCoach, setDiscussionDate,
  setDocumentConfirmationComment,
  updateDocument, validateDocumentEvaluation
} from './slice';
import { selectCurrentEmployee } from '../currentEmployee/selectors';
import { selectCurrentUser } from '../currentUser/selectors';

export const selectCurrentDocumentState = (state: RootState) =>
  state.currentDocument.document;

export const selectCurrentDocument = createSelector(
  selectCurrentDocumentState,
  (state) => state.document
);

export const selectCurrentDocumentCycle = createSelector(
  selectCurrentDocumentState,
  (state) => state.document?.relatedCycle
);

export const selectCurrentDocumentGrade = createSelector(
  selectCurrentDocumentState,
  (state) => state.document?.personalGrading
);

export const isLoadingCurrentDocument = createSelector(
  selectCurrentDocumentState,
  (state) => state.status === RequestStatus.PENDING
);

export const isLoadingUpdateCurrentDocument = createSelector(
  selectCurrentDocumentState,
  (state) =>
    state.actionStatuses[updateDocument.type]?.status === RequestStatus.PENDING
);

export const selectUpdateCurrentDocumentStatus = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[updateDocument.type]?.status
);

export const selectUpdateCurrentDocumentError = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[updateDocument.type]?.error
);

export const isLoadingSetDiscussionDate = createSelector(
  selectCurrentDocumentState,
  (state) =>
    state.actionStatuses[setDiscussionDate.type]?.status ===
    RequestStatus.PENDING
);

export const selectSetDiscussionDateStatus = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[setDiscussionDate.type]?.status
);

export const selectSetDiscussionDateError = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[setDiscussionDate.type]?.error
);

export const isLoadingSetDocumentConfirmationComment = createSelector(
  selectCurrentDocumentState,
  (state) =>
    state.actionStatuses[setDocumentConfirmationComment.type]?.status ===
    RequestStatus.PENDING
);

export const selectSetDocumentConfirmationCommentStatus = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[setDocumentConfirmationComment.type]?.status
);

export const selectSetDocumentConfirmationCommentError = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[setDocumentConfirmationComment.type]?.error
);

export const selectCurrentDocumentMode = createSelector(
  selectCurrentDocumentState,
  (state) => state.mode
);

export const selectCurrentDocumentPhase = createSelector(
  selectCurrentDocumentState,
  (state) => state.document?.relatedPhase
);

export const isLoadingRemoveDocumentCoach = createSelector(
  selectCurrentDocumentState,
  (state) =>
    state.actionStatuses[removeDocumentCoach.type]?.status ===
    RequestStatus.PENDING
);

export const selectRemoveDocumentCoachStatus = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[removeDocumentCoach.type]?.status
);

export const selectRemoveDocumentCoachError = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[removeDocumentCoach.type]?.error
);

export const isLoadingAddGlobalCompetenceEvaluation = createSelector(
  selectCurrentDocumentState,
  (state) =>
    state.actionStatuses[addGlobalCompetenceEvaluation.type]?.status ===
    RequestStatus.PENDING
);

export const selectAddGlobalCompetenceEvaluationStatus = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[addGlobalCompetenceEvaluation.type]?.status
);

export const selectAddGlobalCompetenceEvaluationError = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[addGlobalCompetenceEvaluation.type]?.error
);

export const isLoadingAddDocumentEvaluation = createSelector(
  selectCurrentDocumentState,
  (state) =>
    state.actionStatuses[addDocumentEvaluation.type]?.status ===
    RequestStatus.PENDING
);

export const selectAddDocumentEvaluationStatus = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[addDocumentEvaluation.type]?.status
);

export const selectAddDocumentEvaluationError = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[addDocumentEvaluation.type]?.error
);

export const isLoadingValidateDocumentEvaluation = createSelector(
  selectCurrentDocumentState,
  (state) =>
    state.actionStatuses[validateDocumentEvaluation.type]?.status ===
    RequestStatus.PENDING
);

export const selectValidateDocumentEvaluationStatus = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[validateDocumentEvaluation.type]?.status
);

export const selectValidateDocumentEvaluationError = createSelector(
  selectCurrentDocumentState,
  (state) => state.actionStatuses[validateDocumentEvaluation.type]?.error
);

export const selectShowManagerEvaluation = createSelector(
  [selectCurrentDocumentState, selectCurrentUser, selectCurrentEmployee],
  (state, currentUser, employee) => {
    // We don't want to hide anything in target phase
    if (state.document?.relatedPhase !== Phase.review) return true;

    // If the employee is a manager, we don't want to hide the manager evaluation
    if (employee?._id === state?.document?.relatedManager?._id) return true;

    // Only hide manager evaluation for top 60
    if (!state?.document?.relatedFunction?.scoreObjectives &&
      !state?.document?.relatedFunction?.scoreCompetences) return true;

    // If the user is an admin, we don't want to hide the manager evaluation
    if (currentUser?.isAdmin) return true;

    return !isEmpty(state?.document?.notifications?.manager)
  }
);