import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActionMode, ErrorAction } from '../../models/Actions';
import { RawCompetenceEvaluation } from '../../models/Form/BaseCompetence';
import {
  BaseForm, FormRole, Phase, RawBaseForm, RawBaseFormEvaluation
} from '../../models/Form/BaseForm';
import { RequestStatus } from '../../models/Request';
import { UserRole } from '../../models/User';

interface CurrentDocumentState {
  status: RequestStatus;
  document?: BaseForm;
  mode?: ActionMode;
  error?: Error;
  actionStatuses: {
    [key: string]: {
      status: RequestStatus;
      error?: string;
    };
  };
}

const initialState: CurrentDocumentState = {
  status: RequestStatus.IDLE,
  actionStatuses: {}
};

const currentDocumentSlice = createSlice({
  name: "currentDocument",
  initialState,
  reducers: {
    // Load the document with the given UUID.
    loadDocument(state, action: PayloadAction<{ formUUID: string }>) {
      state.status = RequestStatus.PENDING;

      // Set action mode to read-only by default.
      state.mode = ActionMode.READ_ONLY;
    },
    loadDocumentSuccess(state, action: PayloadAction<{ data: BaseForm }>) {
      const { data } = action.payload;
      state.document = data;
      state.status = RequestStatus.SUCCESS;

      // Set action mode
      const { relatedCycle, relatedPhase, relatedState } = data;
      const isReadOnly =
        relatedState.ukey === 3 ||
        //relatedPhase === "review" ||
        (relatedPhase === Phase.target && relatedCycle.prOpened);
      state.mode = isReadOnly ? ActionMode.READ_ONLY : ActionMode.EDIT;
    },
    loadDocumentError(state, action: ErrorAction) {
      const { error } = action.payload;
      state.error = error;
      state.status = RequestStatus.ERROR;
    },

    // Update the document with the given UUID.
    updateDocument(
      state,
      action: PayloadAction<{ formUUID: string; data: RawBaseForm }>
    ) {
      state.actionStatuses[updateDocument.type] = {
        status: RequestStatus.PENDING
      };
    },
    updateDocumentSuccess(state, action: PayloadAction<{ data: BaseForm }>) {
      const { data } = action.payload;
      state.document = data;
      state.actionStatuses[updateDocument.type] = {
        status: RequestStatus.SUCCESS
      };
    },
    updateDocumentError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[updateDocument.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearUpdateDocumentStatus(state) {
      delete state.actionStatuses[updateDocument.type];
    },

    // Set discussion date for the given document
    setDiscussionDate(
      state,
      action: PayloadAction<{ formUUID: string; discussionDate: Date }>
    ) {
      state.actionStatuses[setDiscussionDate.type] = {
        status: RequestStatus.PENDING
      };
    },
    setDiscussionDateSuccess(state, action: PayloadAction<{ data: BaseForm }>) {
      const { data } = action.payload;
      state.document = data;
      state.actionStatuses[setDiscussionDate.type] = {
        status: RequestStatus.SUCCESS
      };
    },
    setDiscussionDateError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[setDiscussionDate.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearSetDiscussionDateStatus(state) {
      delete state.actionStatuses[setDiscussionDate.type];
    },

    // Set confirmation comment for the given document
    setDocumentConfirmationComment(
      state,
      action: PayloadAction<{ formUUID: string, role: UserRole, comment: string }>
    ) {
      state.actionStatuses[setDocumentConfirmationComment.type] = {
        status: RequestStatus.PENDING
      };
    },
    setDocumentConfirmationCommentSuccess(state, action: PayloadAction<{ data: BaseForm }>) {
      const { data } = action.payload;
      state.document = data;
      state.actionStatuses[setDocumentConfirmationComment.type] = {
        status: RequestStatus.SUCCESS
      };
    },
    setDocumentConfirmationCommentError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[setDocumentConfirmationComment.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearSetDocumentConfirmationCommentStatus(state) {
      delete state.actionStatuses[setDocumentConfirmationComment.type];
    },

    // Remove coach from the given document
    removeDocumentCoach(
      state,
      action: PayloadAction<{ formUUID: string; coachId: string }>
    ) {
      state.actionStatuses[removeDocumentCoach.type] = {
        status: RequestStatus.PENDING
      };
    },
    removeDocumentCoachSuccess(
      state,
      action: PayloadAction<{ data: BaseForm }>
    ) {
      const { data } = action.payload;
      state.document = data;
      state.actionStatuses[removeDocumentCoach.type] = {
        status: RequestStatus.SUCCESS
      };
    },
    removeDocumentCoachError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[removeDocumentCoach.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearRemoveDocumentCoachStatus(state) {
      delete state.actionStatuses[removeDocumentCoach.type];
    },

    // Add a global competence evaluation to the document
    addGlobalCompetenceEvaluation(
      state,
      action: PayloadAction<{ formUUID: string; data: RawCompetenceEvaluation }>
    ) {
      state.actionStatuses[addGlobalCompetenceEvaluation.type] = {
        status: RequestStatus.PENDING
      };
    },
    addGlobalCompetenceEvaluationSuccess(
      state,
      action: PayloadAction<{ data: BaseForm }>
    ) {
      const { data } = action.payload;
      state.document = data;
      state.actionStatuses[addGlobalCompetenceEvaluation.type] = {
        status: RequestStatus.SUCCESS
      };
    },
    addGlobalCompetenceEvaluationError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[addGlobalCompetenceEvaluation.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearAddGlobalCompetenceEvaluationStatus(state) {
      delete state.actionStatuses[addGlobalCompetenceEvaluation.type];
    },

    // Add form evaluation to the given document
    addDocumentEvaluation(
      state,
      action: PayloadAction<{ formUUID: string; role: UserRole, data: RawBaseFormEvaluation }>
    ) {
      state.actionStatuses[addDocumentEvaluation.type] = {
        status: RequestStatus.PENDING
      };
    },
    addDocumentEvaluationSuccess(
      state,
      action: PayloadAction<{ data: BaseForm }>
    ) {
      const { data } = action.payload;
      state.document = data;

      state.actionStatuses[addDocumentEvaluation.type] = {
        status: RequestStatus.SUCCESS
      };
    },
    addDocumentEvaluationError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[addDocumentEvaluation.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearAddDocumentEvaluationStatus(state) {
      delete state.actionStatuses[addDocumentEvaluation.type];
    },

    // Validate evaluation of the given document
    validateDocumentEvaluation(
      state,
      action: PayloadAction<{ formUUID: string, role: FormRole }>
    ) {
      state.actionStatuses[validateDocumentEvaluation.type] = {
        status: RequestStatus.PENDING
      };
    },
    validateDocumentEvaluationSuccess(
      state,
      action: PayloadAction<{ data: BaseForm }>
    ) {
      const { data } = action.payload;
      state.document = data;

      // Set action mode
      const { relatedState, relatedPhase } = data;
      const isReadOnly = relatedState.ukey === 3 && relatedPhase === Phase.review;
      state.mode = isReadOnly ? ActionMode.READ_ONLY : ActionMode.EDIT;

      state.actionStatuses[validateDocumentEvaluation.type] = {
        status: RequestStatus.SUCCESS
      };
    },
    validateDocumentEvaluationError(state, action: ErrorAction) {
      const { payload } = action.payload;
      state.actionStatuses[validateDocumentEvaluation.type] = {
        status: RequestStatus.ERROR,
        error: (payload as string) ?? "error"
      };
    },
    clearValidateDocumentEvaluationStatus(state) {
      delete state.actionStatuses[validateDocumentEvaluation.type];
    }
  }
});

export const {
  loadDocument,
  loadDocumentSuccess,
  loadDocumentError,
  updateDocument,
  updateDocumentSuccess,
  updateDocumentError,
  clearUpdateDocumentStatus,
  setDiscussionDate,
  setDiscussionDateSuccess,
  setDiscussionDateError,
  clearSetDiscussionDateStatus,
  removeDocumentCoach,
  removeDocumentCoachSuccess,
  removeDocumentCoachError,
  clearRemoveDocumentCoachStatus,
  addGlobalCompetenceEvaluation,
  addGlobalCompetenceEvaluationSuccess,
  addGlobalCompetenceEvaluationError,
  clearAddGlobalCompetenceEvaluationStatus,
  addDocumentEvaluation,
  addDocumentEvaluationSuccess,
  addDocumentEvaluationError,
  clearAddDocumentEvaluationStatus,
  validateDocumentEvaluation,
  validateDocumentEvaluationSuccess,
  validateDocumentEvaluationError,
  clearValidateDocumentEvaluationStatus,
  setDocumentConfirmationComment,
  setDocumentConfirmationCommentSuccess,
  setDocumentConfirmationCommentError,
  clearSetDocumentConfirmationCommentStatus
} = currentDocumentSlice.actions;

export default currentDocumentSlice.reducer;
