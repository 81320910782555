import isEmpty from "lodash/isEmpty";
import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

import LinkedOKRsList from "../../../../components/integrations/holaSpirit/LinkedOKRsList";
import { useAddOKRToObjective } from "../../../../hooks/integrations/holaSpirit/useAddOKRToObjective";
import { useRemoveOKRFromObjective } from "../../../../hooks/integrations/holaSpirit/useRemoveOKRFromObjective";
import { ActionMode } from "../../../../models/Actions";
import { BaseObjective } from "../../../../models/Form/BaseObjective";
import { HolaOKR } from "../../../../models/integrations/HolaSpirit/HolaOKR";
import { SupportedLanguages } from "../../../../models/LangField";
import {
  NotificationConfig,
  NotificationType
} from "../../../../models/Notification";
import {
  addDocumentObjectiveOKR,
  removeDocumentObjectiveOKR
} from "../../../../redux/currentDocument/objectives/slice";

const mapDispatchToProps = {
  addOKRToObjective: addDocumentObjectiveOKR,
  removeOKRFromObjective: removeDocumentObjectiveOKR
};

type OwnProps = {
  documentId: string;
  objective: BaseObjective;
  lang: SupportedLanguages;
  mode: ActionMode;
  memberId?: string;
  notify: (notification: NotificationConfig) => void;
};

const ObjectiveMeta: FC<OwnProps & ReduxProps> = (props): ReactElement => {
  const { t } = useTranslation("translation", {
    keyPrefix: "objectives"
  });
  const { t: tCommon } = useTranslation("translation", {
    keyPrefix: "common"
  });
  const {
    documentId,
    objective,
    lang = "fr",
    mode,
    memberId,
    notify,
    addOKRToObjective,
    removeOKRFromObjective
  } = props;

  const mutationLink = useAddOKRToObjective(documentId, objective._id);
  const mutationUnlink = useRemoveOKRFromObjective(documentId, objective._id);

  const onLinkOKR = (okr: HolaOKR) => {
    mutationLink.mutate(okr._id);

    addOKRToObjective({ objectiveId: objective._id, okr });

    notify({
      type: NotificationType.SUCCESS,
      title: tCommon("notifications.saveSuccess"),
      message: tCommon("notifications.okrLinked")
    });
  };

  const onUnlinkOKR = (okr: HolaOKR) => {
    mutationUnlink.mutate(okr._id);

    removeOKRFromObjective({ objectiveId: objective._id, okrId: okr._id });

    notify({
      type: NotificationType.SUCCESS,
      title: tCommon("notifications.saveSuccess"),
      message: tCommon("notifications.okrUnlinked")
    });
  };

  return (
    <section aria-labelledby="applicant-information-title">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="applicant-information-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            {t("meta.title")}
          </h2>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-3">
              <dt className="text-sm font-medium text-gray-500">
                {t("fields.type")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 font-medium">
                {objective.relatedType.name?.[lang]}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t("fields.weight")}
              </dt>
              <dd className="mt-1 text-xl font-semibold text-indigo-600">
                {Math.round(objective.weight)}%
              </dd>
            </div>
            {objective.startDate && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {t("fields.period")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <span className="capitalize">{objective.startDate}</span>
                  {" > "}
                  <span className="capitalize">{objective.endDate}</span>
                </dd>
              </div>
            )}
            {/* <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                {t("fields.progress")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                <ObjectiveProgression objective={objective} />
              </dd>
            </div> */}
            {!isEmpty(objective?.options?.link) && (
              <div className="sm:col-span-3">
                <dt className="text-sm font-medium text-gray-500">
                  {t("fields.link")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900 font-medium">
                  <a
                    href={objective?.options?.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {objective?.options?.link}
                    <ArrowTopRightOnSquareIcon className="h-4 w-4 inline-block ml-1" />
                  </a>
                </dd>
              </div>
            )}
            <div className="sm:col-span-3">
              <dt className="text-sm font-medium text-gray-500">
                {t("fields.description")}
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {!isEmpty(objective.description)
                  ? objective.description
                  : "N/A"}
              </dd>
            </div>
            {objective.comments && (
              <div className="sm:col-span-3">
                <dt className="text-sm font-medium text-gray-500">
                  {t("fields.comments")}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {objective.comments}
                </dd>
              </div>
            )}
            {objective.relatedType.ukey === 3 && (
              <div className="sm:col-span-3">
                <LinkedOKRsList
                  okrs={objective.okrs}
                  label={t("holaSpirit.okrs")}
                  memberId={memberId}
                  onLinkOKR={
                    !objective.isCompleted && mode === ActionMode.EDIT
                      ? onLinkOKR
                      : undefined
                  }
                  onUnlinkOKR={
                    !objective.isCompleted && mode === ActionMode.EDIT
                      ? onUnlinkOKR
                      : undefined
                  }
                />
              </div>
            )}
          </dl>
        </div>
      </div>
    </section>
  );
};

const withConnect = connect(null, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withConnect>;

export default withConnect(ObjectiveMeta);
