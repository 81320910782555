import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import WarningBanner from "../../../../components/banners/WarningBanner";
import ActionButton from "../../../../components/buttons/ActionButton";
import DocumentEvaluationProgress from "../../../../components/documents/DocumentEvaluationProgress";
import SafeHTMLText from "../../../../components/texts/SafeHTMLText";
import { BaseForm, FormRole } from "../../../../models/Form/BaseForm";
import { User } from "../../../../models/User";
import { formatISODateStr } from "../../../../utils/dates";

interface OwnProps {
  currentUser: User;
  document: BaseForm;
  role: FormRole;
  openModal: (modal: any) => void;
}

const PerformanceReviewActions: FC<OwnProps> = (props): ReactElement => {
  const { t } = useTranslation();
  const { document, role, openModal, currentUser } = props;
  const { relatedFunction, relatedState } = document;

  const isValidated = relatedState.ukey === 3;
  const isManager = role === FormRole.manager;

  const getButtonLabel = () => {
    if (isValidated) {
      return t("form.evaluation.actions.results");
    }

    if (isManager) {
      return t("form.evaluation.actions.confirm");
    }

    return t("form.evaluation.actions.status");
  };

  const openValidateModal = () => {
    if (!role) return;

    openModal({
      modal: {
        component: "DocumentValidationModal",
        props: {
          document,
          role,
          lang: currentUser?.lang ?? "en"
        }
      }
    });
  };

  const openValidateResultsModal = () => {
    if (!role) return;

    openModal({
      modal: {
        component: "DocumentValidationResultsModal",
        props: {
          document,
          role,
          lang: currentUser?.lang ?? "en"
        }
      }
    });
  };

  return (
    <div className="flex flex-col flex-col">
      <ActionButton
        label={getButtonLabel()}
        colorVariant="green"
        onClick={isValidated ? openValidateResultsModal : openValidateModal}
        disabled={role == null}
      />

      {!isValidated && role && (
        <div className="mt-5">
          <DocumentEvaluationProgress role={role} />
        </div>
      )}

      {!isValidated &&
        isManager &&
        relatedFunction?.scoreCompetences &&
        relatedFunction?.scoreObjectives &&
        !document.notifications?.manager && (
          <div className="mt-5">
            <WarningBanner
              title={t("form.evaluation.visibility.title")}
              message={t("form.evaluation.visibility.message")}
            />
          </div>
        )}

      {isValidated && document.managerValidationOn && (
        <div className="mt-5 text-center">
          <SafeHTMLText
            html={t("form.evaluation.validatedOn", {
              date: formatISODateStr(document.managerValidationOn)
            })}
            className="text-sm text-grey-900 break-words"
          />
        </div>
      )}
    </div>
  );
};

export default PerformanceReviewActions;
