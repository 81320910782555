import { AxiosResponse, CancelToken } from 'axios';

import { RawCompetenceEvaluation } from '../models/Form/BaseCompetence';
import { BaseForm, RawBaseForm, RawBaseFormEvaluation } from '../models/Form/BaseForm';
import { ShortCoachRequest } from '../models/Form/CoachRequest';
import API from '../utils/api';

export const getForm = async (
  cancelToken: CancelToken,
  formUUID: string
): Promise<BaseForm> => {
  const url = `/form/${formUUID}`;

  try {
    return await API.get(url, {
      cancelToken
    }).then((response: AxiosResponse) => {
      const document: BaseForm = response.data.data;

      return document;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const updateForm = async (
  cancelToken: CancelToken,
  formUUID: string,
  payload: RawBaseForm
): Promise<true> => {
  const url = `/form/${formUUID}/save`;

  try {
    await API.patch(url, payload, {
      cancelToken
    });

    return true;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const notifyForm = async (formUUID: string): Promise<true> => {
  const url = `/form/${formUUID}/notify`;

  try {
    await API.post(url, {});

    return true;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const setFormDiscussionDate = async (
  cancelToken: CancelToken,
  formUUID: string,
  discussionDate: Date
): Promise<true> => {
  const url = `/form/${formUUID}/discussion-date`;

  try {
    await API.patch(
      url,
      { date: discussionDate.toISOString() },
      { cancelToken }
    );

    return true;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const setConfirmationComment = async (
  cancelToken: CancelToken,
  formUUID: string,
  comment: string
): Promise<true> => {
  const url = `/form/${formUUID}/confirmation-comment`;

  try {
    await API.patch(url, { comment }, { cancelToken });

    return true;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const addDocumentCoach = async (
  formUUID: string,
  selectedCoach: string
): Promise<true> => {
  const url = `/form/${formUUID}/coach`;

  try {
    await API.patch(url, { selectedCoach });

    return true;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const deleteDocumentCoach = async (
  formUUID: string,
  coachId: string
): Promise<true> => {
  const url = `/form/${formUUID}/coach/${coachId}`;

  try {
    await API.delete(url);

    return true;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

export const getFormCoachingRequests = async (
  signal: AbortSignal,
  formUUID: string
): Promise<ShortCoachRequest[]> => {
  const url = `/form/${formUUID}/coach-requests`;

  try {
    return await API.get(url, {
      signal
    }).then((response: AxiosResponse) => {
      const requests: ShortCoachRequest[] = response.data.data;

      return requests;
    });
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
};

/**
 * Updates the global competence score for a specific form.
 *
 * @param cancelToken - The token used to cancel the request if needed.
 * @param formUUID - The unique identifier of the form to update.
 * @param payload - The data containing the raw competence evaluation.
 * @returns A promise that resolves to `true` if the update is successful, or rejects with an error if the update fails.
 */
export const updateGlobalCompetenceScore = async (
  cancelToken: CancelToken,
  formUUID: string,
  payload: RawCompetenceEvaluation
): Promise<true> => {
  const url = `/form/${formUUID}/global-score`;

  try {
    await API.patch(url, payload, {
      cancelToken,
    });

    return true;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
}

/**
 * Updates the score of a form identified by its UUID.
 *
 * @param cancelToken - A token that can be used to cancel the request.
 * @param formUUID - The unique identifier of the form to update.
 * @param payload - The data to be sent in the request body, containing the form evaluation details.
 * @returns A promise that resolves to `true` if the update is successful, or rejects with an error if the update fails.
 */
export const updateFormScore = async (
  cancelToken: CancelToken,
  formUUID: string,
  payload: RawBaseFormEvaluation
): Promise<true> => {
  const url = `/form/${formUUID}/score`;

  try {
    await API.patch(url, payload, {
      cancelToken
    });

    return true;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
}

/**
 * Validates a document by its form UUID.
 *
 * @param formUUID - The UUID of the form to validate.
 * @returns A promise that resolves to the updated form if the validation is successful, 
 *          or rejects with an error if the validation fails.
 */
export const validateDocument = async (
  formUUID: string,
): Promise<BaseForm> => {
  const url = `/form/${formUUID}/validate`;

  try {
    const response = await API.post(url);

    return response.data.data;
  } catch (error) {
    return new Promise((resolve, reject) => reject(error));
  }
}