import { SupportedLanguages } from "../LangField";
import { PersonName } from "../User";

export enum ActivityLogResourceType {
  OBJECTIVE = "objective",
  COMPETENCE = "competence",
  DEV_ACTION = "development-action",
  VALUE = "value",
  FORM = "form",
  MEETING = "meeting"
}

export enum ActivityLogActionType {
  CREATE = "create",
  UPDATE = "update",
  DELETE = "delete",
  SET_AS_COMPLETED = "setAsCompleted",
  CONFIRM_EVALUATION = "confirmEvaluation",
  COMMENT = "comment",
  COMMENT_DELETED = "commentDeleted",
  ADD_COACH = "addCoach",
  REMOVE_COACH = "removeCoach",
  ACCEPT_REQUEST = "acceptRequest",
  REJECT_REQUEST = "rejectRequest",
  NOTIFY = "notify",
  SET_DISCUSSION_DATE = "setDiscussionDate",
  SCORE = "score",
  ADD_COURSE = "addCourse",
  REMOVE_COURSE = "removeCourse",
  ADD_COURSE_REGISTRATION = "addCourseRegistration",
  REMOVE_COURSE_REGISTRATION = "removeCourseRegistration",
}

export type ActivityLog = {
  _id: string;
  relatedEmployee: {
    _id: string;
    name: PersonName;
    lang: SupportedLanguages;
  };
  relatedForm: string;
  actionType: ActivityLogActionType;
  resourceType: ActivityLogResourceType;
  resourceId: string;
  elementId?: {
    _id: string;
    body: string;
    createdAt: string;
    createdBy: {
      _id: string;
      name: PersonName;
      lang: SupportedLanguages;
    };
  };

  // Content
  changes: any;

  // Internal management
  createdAt: string;
};
